




























































































































































import { NO_TIER } from '@/constants'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { StakingServiceItemViewModel } from '../viewmodels/staking-service-viewmodel'

@Observer
@Component({})
export default class StakingLPDialog extends Vue {
  @Prop({ default: null }) vm!: StakingServiceItemViewModel
  noTierText = NO_TIER
}
